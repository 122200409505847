
































import Vue from "vue";
import NavigateCard from "@/components/onboarding/NavigateCard.vue";

export default Vue.extend({
  name: "OnboardingChoice",
  data() {
    return {
      recruiter_icon: require("@/assets/illustrations/candidate.svg"),
      candidate_icon: require("@/assets/illustrations/application.svg"),
      navigate_icon: require("@/assets/icons/arrow-right.svg")
    };
  },
  components: { NavigateCard }
});
