






































import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { mapGetters } from "vuex";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";

export default Vue.extend({
  name: "NavigateCard",

  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    description: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    navigate_icon: {
      type: String,
      required: true
    },
    navigate_link: {
      type: String,
      required: true
    }
  }
});
